.App {
  text-align: center;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-inner {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: calc(10px + 2vmin);
  background-image: url("https://assets.unlayer.com/stock-templates1678175891820-wave@4x.png");
  background-repeat: repeat;
  background-position: center center;
  background-color: transparent;
  height: 100vh;
}

.App-logo {
  height: 90px;
  pointer-events: none;
  padding-bottom: 24px;
}

.title {
  margin: 0px;
  line-height: 140%;
  text-align: center;
  word-wrap: break-word;
  font-size: 32px;
  font-weight: 700;
  color: white;
  margin-bottom: 32px;
}

.App-link {
  color: #61dafb;
}

.App-content {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
}

.mainDiv {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  font-family: "Open Sans", sans-serif;
  border-radius: 8px;
  margin-bottom: 24px;
}

.cardStyle {
  width: 500px;
  border-color: white;
  background: #fff;
  padding: 32px;
  box-shadow: 0px 0 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
#signupLogo {
  max-height: 100px;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.formTitle {
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  color: #2f2d3b;
  text-align: center;
}
.inputLabel {
  font-size: 12px;
  color: #555;
  margin-bottom: 6px;
  margin-top: 24px;
}
.inputDiv {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;
}
input {
  height: 40px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  border: solid 1px #ccc;
  padding: 0 11px;
}
input:disabled {
  cursor: not-allowed;
  border: solid 1px #eee;
}
.buttonWrapper {
  margin-top: 40px;
}
.submitButton {
  width: 70%;
  height: 40px;
  margin: auto;
  display: block;
  color: #fff;
  background-color: #6C293C;
  border-color: #065492;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border: 0px;
}
.submitButton:disabled,
button[disabled] {
  border: 1px solid #989898;
  background-color: #989898;
  color: white;
}

#loader {
  position: absolute;
  z-index: 1;
  margin: -2px 0 0 10px;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #666666;
  width: 14px;
  height: 14px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  padding: 0px 12px;
}

.footerText {
  font-size: 16px;
  font-weight: 500;
  color: white
}

.footerText a {
  display: flex;
  color: white;
  font-weight: 500;
  margin-top: 8px;
}


.badgeContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 8px 24px;
  justify-content: center;
}

.badge {
  border: 1px solid #DADADA;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 4px;
  margin-top: 4px;
}

.errorText {
  color: #c80000;
  font-size: 12px;
  text-align: center;
}

.successText {
  color: #4ca239;
  font-size: 12px;
  text-align: center;
}

.successTitle {
  color: #4ca239;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.showPasswordContainer {
  position: absolute;
  right: 10px;
  bottom: 0px;
  cursor: pointer;
}

.showPasswordContainer img {
  width: 20px;
  height: 20px;
  padding: 0;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 620px) {
  .mainDiv {
    width: 100%;
  }
  .cardStyle {
    width: 100%;
  }
  .inputDiv {
    width: 100%;
  }
  .submitButton {
    width: 100%;
  }
}